document.addEventListener('DOMContentLoaded', function () {
  const checkbox = document.querySelector('#certificationRequiredCheck');
  const submitButton = document.querySelector('.subscription-submit-btn');

  if (checkbox && submitButton) {
    submitButton.disabled = !checkbox.checked;

    checkbox.addEventListener('change', function () {
      submitButton.disabled = !checkbox.checked;
    });
  } else {
    console.error('Checkbox or submit button not found.');
  }
});
