// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import * as ActiveStorage from "@rails/activestorage";

require("@rails/ujs").start();
ActiveStorage.start();

require("channels");
//import "@hotwired/turbo-rails"

import "controllers";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import $ from "jquery";
import "popper.js";
import "bootstrap";
import toastr from "toastr";
import "select2";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "src/scss/application";
import "./application/funds";
import "./application/users";
import "./application/accounts";
import "./application/distributors";
import "./application/insurance_members";
import "./application/nominee_members";
import "./application/users/profiles/naturals/step_0.js";
import "./application/users/profiles/naturals/step_1.js";
import "./application/users/profiles/naturals/step_2.js";
import "./application/users/profiles/naturals/step_4.js";
import "./application/users/profiles/naturals/step_5.js";
import "./application/users/profiles/naturals/step_6.js";
import "./application/users/profiles/naturals/step_7.js";
import "./application/users/profiles/naturals/step_8.js";
import "./application/users/profiles/naturals/step_10.js";

import "./application/users/profiles/legals/step_0.js";
import "./application/users/profiles/legals/step_1.js";
//import "./application/users/profiles/legals/step_2.js";
import "./application/users/profiles/legals/step_3.js";
import "./application/users/profiles/legals/step_5.js";
import "./application/users/profiles/legals/step_6.js";
import "./application/users/profiles/legals/step_7.js";
import "./application/users/profiles/legals/step_8.js";
import "./application/users/profiles/legals/step_10.js";
import "./application/users/profiles/legals/step_11.js";
import "./application/users/profiles/input_file.js";
import "./application/users/subscriptions/step_0.js";
import "./application/users/subscriptions/step_2.js";
import "./application/insurance_members/subscriptions/step_0.js";
import "./application/distributors/users/create_user_modal.js";
import "./application/distributors/profile_documents";
import "./application/maintenance/underline";
import { openWarningModal } from "src/js/modal";
import "../src/javascript/phoneNumberFormatter.js";
import { initFlatpickr } from "src/js/flatpickr";
import * as echarts from "echarts";
import "echarts/theme/dark";

window.echarts = echarts;

toastr.options.toastClass = "toastr";

window.Swal = Swal;
window.toastr = toastr;

$(function () {
  $('[data-toggle="tooltip"]').tooltip();
});

export function showElement(selector) {
  selector.removeClass("d-none");
  selector.addClass("d-block");
}

export function hideElement(selector) {
  selector.removeClass("d-block");
  selector.addClass("d-none");
}

export function showInvalidFeedback() {
  const invalidFeedbackDiv = $('div[id$="-invalid-feedback"]');

  invalidFeedbackDiv.each(function () {
    if ($(this).text()) {
      $(this).parents(".row").removeClass("d-none");
    }
  });
}

export const showNumberInputsOnLoad = (inputs) => {
  inputs.each((_index, el) => {
    if ($(el).val() !== "") {
      $(el).parents(".row:first").removeClass("d-none");
    }
  });
};

export const showCheckedInputsOnLoad = (inputs) => {
  inputs.each((_index, el) => {
    if ($(el).is(":checked")) {
      $(el).parents(".row:first").removeClass("d-none");
    }
  });
};

function toggleFiscalResidenceFields() {
  if ($("#users_profile_natural_has_fiscal_residence_true").is(":checked")) {
    $("#fiscal-residence-fields").removeClass("d-none");
  } else {
    $("#fiscal-residence-fields").addClass("d-none");
  }
}

function chooseProfileForInvestment() {
  if ($("select#profile")[0] && $("a#select-share-link")[0]) {
    const profile = $("select#profile").val();

    $("a#select-share-link").attr(
      "href",
      $("a#select-share-link")
        .attr("href")
        .replace(/&profile=\d*&?/, "") + `&profile=${profile}`,
    );
  }
}

function togglePolicallyExposedPersonField() {
  if (
    $("#users_profile_natural_is_politically_exposed_person_true").is(
      ":checked",
    )
  ) {
    $("#is-politically-exposed-person-fields").removeClass("d-none");
  } else {
    $("#is-politically-exposed-person-fields").addClass("d-none");
  }
}

function toggleCloseToPolicallyExposedPersonField() {
  if (
    $("#users_profile_natural_is_close_to_politically_exposed_person_true").is(
      ":checked",
    )
  ) {
    $("#is-close-politically-exposed-person-fields").removeClass("d-none");
  } else {
    $("#is-close-politically-exposed-person-fields").addClass("d-none");
  }
}

$(document).ready(() => {
  if ($(".flash.notice")[0]) {
    toastr["info"]($(".flash.notice").html()); // toastr doesn't have a "notice" method!
  }
  if ($(".flash.info")[0]) {
    toastr["info"]($(".flash.info").html());
  }
  if ($(".flash.success")[0]) {
    toastr["success"]($(".flash.success").html());
  }
  if ($(".flash.error")[0]) {
    toastr["error"]($(".flash.error").html());
  }
  if ($(".flash.alert")[0]) {
    toastr["error"]($(".flash.alert").html());
  }

  $("select").select2();

  $("#profile_id").select2({
    dropdownParent: $("#chooseProfile"),
  });
  $("#user_id").select2({
    dropdownParent: $("#sendFund"),
  });
  $("#user_share_id").select2({
    dropdownParent: $("#sendFund"),
  });
  $("#insurance_network_id").select2({
    dropdownParent: $("#newInsurance"),
  });

  toggleFiscalResidenceFields();
  $("body").on(
    "change",
    'input[type="radio"][name="users_profile_natural[has_fiscal_residence]"]',
    toggleFiscalResidenceFields,
  );

  $("select#profile").on("change", chooseProfileForInvestment);
  chooseProfileForInvestment();

  togglePolicallyExposedPersonField();
  $("body").on(
    "change",
    'input[type="radio"][name="users_profile_natural[is_politically_exposed_person]"]',
    togglePolicallyExposedPersonField,
  );

  toggleCloseToPolicallyExposedPersonField();
  $("body").on(
    "change",
    'input[type="radio"][name="users_profile_natural[is_close_to_politically_exposed_person]"]',
    toggleCloseToPolicallyExposedPersonField,
  );

  openWarningModal();
  initFlatpickr();
});
